export const apiUrl = process.env.VUE_APP_API_URL;

export const defaultLanguageId = 1;
export const needLanguage =  false;
export const languages = {
    1: {
        id: 1,
        code: "hu",
        target_link : ""
    }
   
};
export const defaultPage = 'Home';
export const project = {
        title: 'Kunsági Éden'
};
