import Vue from 'vue'
import VueRouter from 'vue-router'

import Footer from '@/components/layout/Footer.vue'
import Header from '@/components/layout/Header.vue'

Vue.use(VueRouter)

  const routes = [    
    {
      path: '/', 
      name: 'Home',
      components: {
        default: () => import('@/views/Home.vue'),
        header: Header,
        footer: Footer    
      },
    },   
    {
      path: '/rolunk', 
      name: 'About',
      components: {
        default: () => import('@/views/About.vue'),
        header: Header,
        footer: Footer
      },
    },  
    {
      path: '/aktualitasok', 
      name: 'News',
      components: {
        default: () => import('@/views/News.vue'),
        header: Header,
        footer: Footer
      }      
    },  
    {
      path: '/aktualitasok/hirek/:news_slug', 
      name: 'NewsDetails',
      components: {
        default: () => import('@/views/NewsDetails.vue'),
        header: Header,
        footer: Footer
      }      
    },     
    {
      path: '/aktualitasok/promociok/:promo_slug', 
      name: 'PromoDetails',
      components: {
        default: () => import('@/views/PromoDetails.vue'),
        header: Header,
        footer: Footer
      }      
    },     
    {
      path: '/termekeink', 
      name: 'Products',
      components: {
        default: () => import('@/views/Products.vue'),
        header: Header,
        footer: Footer
      },
    },    
    {
      path: '/receptek', 
      name: 'Recipes',
      components: {
        default: () => import('@/views/Recipes.vue'),
        header: Header,
        footer: Footer
      },
    },    
    {
      path: '/receptek/:recipe_slug', 
      name: 'RecipeDetails',
      components: {
        default: () => import('@/views/RecipeDetails.vue'),
        header: Header,
        footer: Footer
      },
    },    
    {
      path: '/kapcsolat', 
      name: 'Contact',
      components: {
        default: () => import('@/views/Contact.vue'),
        header: Header,
        footer: Footer
      },
    },    
    {
      path: '/404',
      name: '404-page',          
      component: () => import('@/views/PageNotFound.vue'),
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
})

export default router
