<template>
    <header class="main-header">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <b-navbar toggleable="md" >
                        <router-link :to="{name: 'Home'}" class="navbar-brand" @click.native="setDataLayer('header', 'click', 'logo')">
                            <div class="logo-wrap">
                                <img src="@/assets/images/brand/logo-kunsagi.svg" alt="Kunsági Éden" class="w-100"  />
                            </div>                            
                        </router-link>

                        <b-navbar-toggle id="navbar-toggle" target="nav-collapse" @click="toggleOpenClass"></b-navbar-toggle>
                        
                        <b-collapse id="nav-collapse" is-nav >
                            <div class="nav-collapse__inner">                    
                                <b-navbar-nav class="navbar__main-menu" >
                                    <li class="nav-link">
                                        <router-link :to="{path: '/'}" class="homepage-nav" @click.native="setDataLayer('header', 'click', 'fooldal')">Főoldal</router-link>
                                    </li>
                                    <!-- <li class="nav-link">
                                        <router-link :to="{path: '/aktualitasok'}" @click.native="setDataLayer('header', 'click', 'aktualitasok')">Aktualitások</router-link>
                                    </li> -->
                                    <li class="nav-link">
                                        <router-link :to="{path: '/termekeink'}" @click.native="setDataLayer('header', 'click', 'termekeink')">Termékeink</router-link>
                                    </li>
                                    <li class="nav-link">
                                        <router-link :to="{path: '/receptek'}" @click.native="setDataLayer('header', 'click', 'receptek')">Receptek</router-link>
                                    </li>
                                    <li class="nav-link">
                                        <router-link :to="{path: '/rolunk'}" @click.native="setDataLayer('header', 'click', 'rolunk')">Rólunk</router-link>
                                    </li>
                                    <li class="nav-link">
                                        <router-link :to="{path: '/kapcsolat'}" @click.native="setDataLayer('header', 'click', 'kapcsolat')">Kapcsolat</router-link>
                                    </li>
                                </b-navbar-nav>  
                                <b-navbar-nav class="navbar__social">
                                    <li class="nav-link">
                                        <a href="https://www.instagram.com/kunsagieden/" target="_blank" class="instagram btn--social"
                                        @click="setDataLayer('header', 'click', 'instagram')"
                                        ></a>
                                    </li>
                                    <li class="nav-link">
                                        <a href="https://www.facebook.com/kunsagieden" target="_blank" class="facebook btn--social"
                                        @click="setDataLayer('header', 'click', 'facebook')"></a>
                                    </li>
                                </b-navbar-nav>
                            </div>                                                                 
                        </b-collapse>
                    </b-navbar>
                </b-col>                            
            </b-row>
        </b-container>
    </header>
</template>

<script>
export default {
    methods:{
        toggleOpenClass(){            
            
            let element = document.getElementById('nav-collapse')     
            let body = document.getElementsByTagName('body')[0]       

            if (element){                            
                this.navIsOpen = !element.classList.contains('show')
            } else {
                this.navIsOpen = false
            }

            if (this.navIsOpen){
                body.classList.add('noScroll')
            } else {
                body.classList.remove('noScroll')
            }
        },
    }
}
</script>