import * as CONFIG from '@/setup/init.js';

export default {
    data: function(){
        return {
            CONFIG: CONFIG,
            monthNames: [
                {id: 1, name: 'Január'},
                {id: 2, name: 'Február'},
                {id: 3, name: 'Március'},
                {id: 4, name: 'Április'},
                {id: 5, name: 'Május'},
                {id: 6, name: 'Június'},
                {id: 7, name: 'Július'},
                {id: 8, name: 'Augusztus'},
                {id: 9, name: 'Szeptember'},
                {id: 10, name: 'Október'},
                {id: 11, name: 'November'},
                {id: 12, name: 'December'}
            ]
        }
    },   
    created(){
        // console.log('globalmi')
    },
    methods: {
        scrollTop (){
            document.getElementsByTagName('html')[0].scrollTop = 0
        },
        itemSrcset(item){
            if (item.image){
                return item.image.srcset
            } else {
                return "no-image"
            }
        },
        itemSrc(item, cacheSize){
            if (item.image){            
                let cacheSrcs = Object.entries(item.image.cache)                        
    
                for (let [srckey, srcvalue] of cacheSrcs) {                                                  
                    if (srckey == cacheSize){                                      
                        return srcvalue
                    } 
                }
            } else {
                return "no-image"
            }
        },
        hasHttp(link){
            return link.includes("http") ? true : false
        },
        getTimeDate(rawDate){      
            let date = rawDate.slice(0,10)
            let d = date.replace(/-/g, "");             
            let dY = parseInt(d.slice(0,4));
            let dM = parseInt(d.slice(4,6));
            let dD = parseInt(d.slice(6));            
            
            let monthName = this.getMonthName(parseInt(dM))
            let formattedDate = dY + '. ' + monthName + ' ' + dD + '.'
  
            return formattedDate 
        },
        getMonthName(montId){            
            return this.monthNames.find( x => x.id == montId).name
        },
        url_slug: function(s, opt) {
            s = String(s);
            // opt = Object(opt);

            var defaults = {
                'delimiter': '-',
                'limit': undefined,
                'lowercase': true,
                'replacements': {},    
                'transliterate': true
            };
            
            opt = {}
            for (var k in defaults) {
                opt[k] = defaults[k];
            }

            var char_map = {
                // Latin
                'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
                'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
                'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
                'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
                'ß': 'ss',
                'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
                'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
                'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
                'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
                'ÿ': 'y',    
            };

            // Make custom replacements
            for (let k in opt.replacements) {
                s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
            }

            // Transliterate characters to ASCII
            if (opt.transliterate) {
                for (let k in char_map) {
                s = s.replace(RegExp(k, 'g'), char_map[k]);
                }
            }

            // Replace non-alphanumeric characters with our delimiter
            // var alnum = (typeof(XRegExp) === 'undefined') ? RegExp('[^a-z0-9]+', 'ig') : XRegExp('[^\\p{L}\\p{N}]+', 'ig');
            var alnum = RegExp('[^a-z0-9]+', 'ig')
            s = s.replace(alnum, opt.delimiter);

            // Remove duplicate delimiters
            s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

            // Truncate slug to max. characters
            s = s.substring(0, opt.limit);

            // Remove delimiter from ends
            s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

            return opt.lowercase ? s.toLowerCase() : s;
        },
        GTtrack ( category, action, label){
            // console.log("track " , category, action, label);
            // console.log(window.dataLayer)
            try {
                window.dataLayer.push({
                    'event': 'event',
                    'category': category,
                    'action': action,
                    'label': label
                });
            } catch (e) {
                console.log("not found dataLayer")
            }     
        },
        setDataLayer(category, action, label){
            let acceptMarketingCookie = this.$cookies.get('marketing_cookie');                      
            let acceptStatCookie = this.$cookies.get('statistic_cookie');                      

            if ( parseInt(acceptMarketingCookie) === 1 || parseInt(acceptStatCookie) === 1){            
                this.GTtrack( category, action, label);                
            } 
        },
        deleteMetaTags(){
            //clear the meta tags

            document.title = ''
            
            let metaDesc = document.querySelectorAll('meta[name="description"]')
      
            if (metaDesc.length > 0){                
                let elem = metaDesc[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaKeywords = document.querySelectorAll('meta[name="keywords"]')
      
            if (metaKeywords.length > 0){
                let elem = metaKeywords[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgTitle = document.querySelectorAll('meta[property="og:title"]')
      
            if (metaOgTitle.length > 0){
                let elem = metaOgTitle[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgType = document.querySelectorAll('meta[property="og:type"]')
      
            if (metaOgType.length > 0){
                let elem = metaOgType[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgSiteName = document.querySelectorAll('meta[property="og:site_name"]')
      
            if (metaOgSiteName.length > 0){
                let elem = metaOgSiteName[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgUrl = document.querySelectorAll('meta[property="og:url"]')
      
            if (metaOgUrl.length > 0){
                let elem = metaOgUrl[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaogImage = document.querySelectorAll('meta[property="og:image"]')
      
            if (metaogImage.length > 0){
                let elem = metaogImage[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgImageWidth = document.querySelectorAll('meta[property="og:image:width"]')
      
            if (metaOgImageWidth.length > 0){
                let elem = metaOgImageWidth[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgImageHeight = document.querySelectorAll('meta[property="og:image:height"]')
      
            if (metaOgImageHeight.length > 0){
                let elem = metaOgImageHeight[0]            
                elem.parentNode.removeChild(elem)
            }
    
            let metaOgDesc = document.querySelectorAll('meta[property="og:description"]')
      
            if (metaOgDesc.length > 0){            
                let elem = metaOgDesc[0]            
                elem.parentNode.removeChild(elem)
            }
        },
        setMetaTags( metaTags){
            //clear the meta tags - need this because duplicate data call - example products category list page
            this.deleteMetaTags()        
    
            // this.setMetaTags({
            //     title: title, 
            //     desc: desc, 
            //     keywords:keywords, 
            //     ogType:ogType, 
            //     ogTitle:ogTitle,                
            //     ogImage:ogImage, 
            //     ogImageWidth:ogImageWidth, 
            //     ogImageHeight:ogImageHeight, 
            //     ogDesc:ogDesc
            // })
            
            
            //add page title
            if (metaTags.title != null) {
                document.title = metaTags.title
            } else {
                document.title = 'Kunsági Éden'
            }                    
    
            //add meta tags
            if (metaTags.desc != null) {            
                let metaDesc = document.createElement('meta')
                metaDesc.name = "description"
                metaDesc.content = metaTags.desc 
                document.getElementsByTagName('head')[0].appendChild(metaDesc);
            } else {
                let metaDesc = document.createElement('meta')
                metaDesc.name = "description"
                metaDesc.content = this.lang('meta_description_homepage') 
                document.getElementsByTagName('head')[0].appendChild(metaDesc);
            }       
    
            //meta keywords
            if (metaTags.keywords != null) {
                let metaKeywords = document.createElement('meta')
                metaKeywords.name = "keywords"
                metaKeywords.content = metaTags.keywords
                document.getElementsByTagName('head')[0].appendChild(metaKeywords);
            }       
            
            //og:type 
            if (metaTags.ogType != null) {
                let metaOgType = document.createElement('meta')
                metaOgType.setAttribute('property', 'og:type') 
                metaOgType.content = metaTags.ogType
                document.getElementsByTagName('head')[0].appendChild(metaOgType);
            }        
    
            //og:title
            if (metaTags.ogTitle != null) {
                let metaOgTitle = document.createElement('meta')
                metaOgTitle.setAttribute('property', 'og:title')
                metaOgTitle.content = metaTags.ogTitle
                document.getElementsByTagName('head')[0].appendChild(metaOgTitle);
            } else {
                let metaOgTitle = document.createElement('meta')
                metaOgTitle.setAttribute('property', 'og:title')
                metaOgTitle.content = 'Kunsági Éden'
                document.getElementsByTagName('head')[0].appendChild(metaOgTitle);
            }            
    
            //og:url - FIX
            let metaOgUrl = document.createElement('meta')
            metaOgUrl.setAttribute('property', 'og:url')
            metaOgUrl.content = process.env.VUE_APP_META_URL + window.location.pathname
            document.getElementsByTagName('head')[0].appendChild(metaOgUrl);
    
            //og:image
    
            if (metaTags.ogImage != null) {
                let metaOgImage = document.createElement('meta')
                metaOgImage.setAttribute('property', 'og:image')
                metaOgImage.content = metaTags.ogImage
                document.getElementsByTagName('head')[0].appendChild(metaOgImage);
        
    
                //og:image:width
                if ( metaTags.ogImageWidth != null) {
                    let metaOgImageWidth = document.createElement('meta')
                    metaOgImageWidth.setAttribute('property', 'og:image:width')
                    metaOgImageWidth.content = metaTags.ogImageWidth
                    document.getElementsByTagName('head')[0].appendChild(metaOgImageWidth);
                }           
        
                //og:image:height
                if (metaTags.ogImageHeight != null) {
                    let metaOgImageHeight = document.createElement('meta')
                    metaOgImageHeight.setAttribute('property', 'og:image:height')
                    metaOgImageHeight.content = metaTags.ogImageHeight
                    document.getElementsByTagName('head')[0].appendChild(metaOgImageHeight);
                }
                
            } else {
                let metaOgImage = document.createElement('meta')
                metaOgImage.setAttribute('property', 'og:image')
                metaOgImage.content = process.env.VUE_APP_BASE_URL + '/images/kunsagi-logo_600x315.png'
                document.getElementsByTagName('head')[0].appendChild(metaOgImage);
        
                //og:image:width
                let metaOgImageWidth = document.createElement('meta')
                metaOgImageWidth.setAttribute('property', 'og:image:width')
                metaOgImageWidth.content = '600'
                document.getElementsByTagName('head')[0].appendChild(metaOgImageWidth);
        
                //og:image:height
                let metaOgImageHeight = document.createElement('meta')
                metaOgImageHeight.setAttribute('property', 'og:image:height')
                metaOgImageHeight.content = '315'
                document.getElementsByTagName('head')[0].appendChild(metaOgImageHeight);
            }
            
            //og:description
            if (metaTags.ogDesc != null) {
                let metaOgDesc = document.createElement('meta')
                metaOgDesc.setAttribute('property', 'og:description')
                metaOgDesc.content = metaTags.ogDesc
                document.getElementsByTagName('head')[0].appendChild(metaOgDesc);
            } else {
                let metaOgDesc = document.createElement('meta')
                metaOgDesc.setAttribute('property', 'og:description')
                metaOgDesc.content = this.lang('meta_description_homepage')
                document.getElementsByTagName('head')[0].appendChild(metaOgDesc);
            }
            
        
        }, 
    }
}
