<template>
  <div id="app">       
    <router-view name="header"/>
    <router-view/>
    <router-view name="footer"/>
    <CookiePolicy  />
  </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import CookiePolicy from '@/components/layout/CookiePolicy.vue'

export default {
  mixins: [apiH],
  created(){
    this.$store.dispatch("getProducts");
  },
  components:{
    CookiePolicy
  },
  watch:{
    $route(){
      // console.log('route change')
      this.scrollTop();
      this.deleteMetaTags();          
      // this.setDataLayer('Navigation','routechange','empty');
       window.dataLayer.push({
                    'event': 'eventNavigation',
                    'category': '',
                    'action': '',
                    'label': ''
                });

      //remove - because of mobile menu
      let body = document.getElementsByTagName('body')[0]       
      body.classList.remove('noScroll')
    }
  }
}
</script>