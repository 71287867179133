import Vue from 'vue'
import Vuex from 'vuex'
import * as CONFIG from '@/setup/init.js';
import axios from 'axios'

Vue.use(Vuex)

const timeStamp = Math.floor( Date.now() /1000)

export default new Vuex.Store({
  state: {
    products:[],
    apiCalls: {},
    userType: 'visitor',
    language_id : CONFIG.defaultLanguageId,
  },
  mutations: {
    SETPRODUCTS(state, data){
      state.products = data
    }
  },
  actions: {
    getProducts({ commit }) {
      return new Promise((resolve) => {          
          axios.get(process.env.BASE_URL + 'products.json?' + timeStamp).then(response => {            
            commit("SETPRODUCTS", response.data.products)
            resolve()          
          })
          .catch(() => console.log('error getproducts'));
        })
    } 
  }
})
