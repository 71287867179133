<template>
       <div id="cookie-policy" class="cookie-policy" v-if="needCookieSettings">            
          <div class="cookie-policy__inner">
                                                                                 
                    <div class="text">
                        <div>
                            Tájékoztatjuk, hogy a honlap cookie-kat (sütiket) használ, melyek lehetővé teszik a honlap teljes körű működését, valamint segítik a honlap használatát és hozzájárulnak a személyre szabott tartalmak és hirdetések elhelyezéshez, valamint a közösségi média funkciók biztosításához.
                        </div>
                        <img src="@/assets/images/design/dot-line-no-result.svg" class="design-line" />
                        <div>
                            Az „Igen” gombra kattintással valamennyi cél eléréséhez szükséges süti alkalmazása jóváhagyásra kerül, ugyanakkor a „Beállítások” gombra kattintva lehetőség nyílik a honlapon használt sütik alkalmazásáról dönteni és kiválasztani, hogy milyen célú sütiket kíván engedélyezni. 
                            <div class="link-wrap">
                                <a :href="baseUrl + '/data/Cookie_tajekoztato.pdf'" target="_blank">Cookie (süti) tájékoztató. </a>
                            </div>                            
                        </div>
                    </div>
                    <!-- <button type="button" class="btn--close" onclick="closeCookiePolicy()"></button> -->
                    <div class="cookies-setting" v-if="changeCookies">
                        <div class="custom-control custom-checkbox">
                            <input true-value="1" false-value="0" v-model="cookies.required" type="checkbox" class="custom-control-input" id="required_chbx"  disabled />
                            <label class="custom-control-label" for="required_chbx">Funkcionális sütik</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input true-value="1" false-value="0" v-model="cookies.statistic" type="checkbox" class="custom-control-input" id="statistics_chbx" />
                            <label class="custom-control-label" for="statistics_chbx">Analitikai sütik</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input true-value="1" false-value="0" v-model="cookies.marketing" type="checkbox" class="custom-control-input" id="marketing_chbx" />
                            <label class="custom-control-label" for="marketing_chbx">Marketing sütik</label>
                        </div>
                    </div>
                    <div class="btn-wrap">                                                                                            
                        <button type="button" v-if="!changeCookies" class="btn--white btn" @click="showCookieSettings()">Beállítások</button>
                        <button type="button" v-if="changeCookies" class="btn--white btn" @click="saveCookieSettings()">Beállítások mentése és kilépés</button>
                        <button type="button" class="btn--brown btn" @click="acceptCookies()">Igen!</button>
                    </div>                                                  
                           
            </div>            
        </div>
</template>

<script>
export default {
    data(){
        return{
            needCookieSettings: true,
            changeCookies: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
            cookies:{
                required: '1',
                marketing: '0',
                statistic: '0'
            },
            expiredDate: 60 * 60 * 24 * 30
        }
    },   
    mounted(){
      var _this = this

      if ( parseInt(this.$cookies.get("required_cookie")) === 1 ){          
        _this.needCookieSettings = false
        this.setGTMScriptTag();    
      }
    }, 
    methods:{
        acceptCookies(){
            // required_cookie, marketing_cookie, statistic_cookie            
            this.$cookies.set('required_cookie', 1, this.expiredDate);
            this.$cookies.set('marketing_cookie', 1, this.expiredDate);
            this.$cookies.set('statistic_cookie', 1, this.expiredDate);

            this.needCookieSettings = false
            this.setGTMScriptTag();            
        },
        showCookieSettings(){
            this.changeCookies = true
        },
        saveCookieSettings(){
            this.$cookies.set('required_cookie', 1, this.expiredDate);
            if ( parseInt(this.cookies.marketing) === 1 ){
                this.$cookies.set('marketing_cookie', 1, this.expiredDate);
            }
            if ( parseInt(this.cookies.statistic) === 1 ){
                this.$cookies.set('statistic_cookie', 1, this.expiredDate);
            }

            this.needCookieSettings = false
            if ( parseInt(this.cookies.marketing) === 1  ||  parseInt(this.cookies.statistic) === 1 ){
                this.setGTMScriptTag();
                document.getElementById('main-content').removeAttribute('style');
            }
            
        },
        setGTMScriptTag(){    
            //add GTM script
            let gtmScriptHeadPlace = window.document.getElementsByTagName('head')[0]            
            let gtmScript = window.document.createElement('script')
            if (window.location.hostname == 'localhost'){
                gtmScript.innerHTML = "console.log('gtm script place')"
            } else {                
                gtmScript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': \n" +
                "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], \n" + 
                "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= \n" +
                "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); \n" +
                "})(window,document,'script','dataLayer','GTM-5GDWRCD');";
            }           
            
            gtmScriptHeadPlace.appendChild(gtmScript);  

            //noscript tag
            let gtmScriptBodyPlace = window.document.getElementsByTagName('body')[0]            
            let gtmScriptNoScript = window.document.createElement('noscript')            
            if (window.location.hostname == 'localhost') {
                gtmScriptNoScript.innerHTML = "console.log('gtm script noscript place')";
            } else {                
                gtmScriptNoScript.innerHTML = "<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-5GDWRCD\" \n" +
                "height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"
            }
        
            gtmScriptBodyPlace.insertBefore(gtmScriptNoScript, gtmScriptBodyPlace.childNodes[0]);  
        }        
    }
}
</script>
