<template>
    <footer class="main-footer">
        <b-container fluid="xl">
            <div class="footer__row">
                <div class="logo-wrap footer__logo">
                    <img src="@/assets/images/brand/logo-kunsagi.svg" alt="Kunsági Éden" class="w-100"  />
                </div>  
                <b-navbar-nav class="navbar__social footer__social">
                    <li class="nav-link" >
                        <a 
                            href="https://www.instagram.com/kunsagieden/" 
                            target="_blank" class="instagram btn--social"
                            @click="setDataLayer('footer', 'click', 'instagram')"
                        ></a>
                    </li>
                    <li class="nav-link">
                        <a 
                            href="https://www.facebook.com/kunsagieden" 
                            target="_blank" class="facebook btn--social"
                            @click="setDataLayer('footer', 'click', 'facebook')"
                        ></a>
                    </li>
                </b-navbar-nav>
            </div>
            <div class="footer__row">
                <span>Copyright © 2017–2018 NT Élelmiszertermelő és Kereskedelmi Kft.</span>
                <ul class="footer__law">
                    <li>                        
                        <a target="_blank"
                            :href="baseUrl + '/data/Tajekoztato.pdf'"
                            @click="setDataLayer('footer', 'click', 'visszaeles bejelentesi tajekoztato')"
                        >Visszaélés-bejelentés</a>
                    </li>  
                    <li>                        
                        <a target="_blank"
                            :href="baseUrl + '/data/Information-en.pdf'"
                            @click="setDataLayer('footer', 'click', 'visszaeles bejelentesi tajekoztato en')"
                        >Whistleblowing system</a>
                    </li>  
                    <li>                        
                        <a target="_blank"
                            :href="baseUrl + '/data/ADATKEZELESI_TAJEKOZTATO_2021.pdf'"
                            @click="setDataLayer('footer', 'click', 'adatkezelesi tajekoztato')"
                        >Adatkezelési tájékoztató</a>
                    </li>                
                </ul>
            </div>
        </b-container>
    </footer>
</template>

<script>
export default {
    computed:{
        baseUrl(){
            return process.env.VUE_APP_BASE_URL
        }
    }
}
</script>